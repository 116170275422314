* {
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
}

body {
	background-color: white;
	font-weight: 400;
}

p {
	margin-bottom: 0.5rem;
}

select {
	margin-bottom: 0;
}

label {
	margin: 0;
}

input {
	color: black !important;
}

.invalid-feedback {
	display: block !important;
}

.is-invalid {
	border-color: #dc3545 !important;
}

.is-invalid:focus {
	border-bottom: 1px solid #dc3545 !important;
	box-shadow: 0 1px 0 0 #dc3545 !important;
}

.activeTab {
	border-bottom: solid 2px white;
	padding-bottom: 5px;
	background-color: rgb(55, 57, 64);
}

.card-body {
	padding: 0.9rem;
}

.md-form {
	margin: 1rem 0;
}

.md-form .prefix ~ label {
	transform: translateY(-75%);
}

.push-label-up ~ label {
	transform: translateY(-80%) !important;
}

.pointer {
	cursor: pointer;
}

.default-icon-color {
	color: #424242;
}

.btn-default {
	background-color: #3a465f !important;
}

.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
	background-color: #475e94 !important;
	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.blue-link {
	color: #4285f4 !important;
	font-weight: bold;
	cursor: pointer;
}

.blue-link:hover {
	text-decoration: underline;
	text-decoration-color: #4285f4;
}

.space-between {
	display: flex;
	justify-content: space-between;
}

.antispam {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	width: 0;
	z-index: -10;
}
